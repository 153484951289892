import React from "react"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

export default () => (
  <Layout>
    <HeadMeta pageTitle={"Enquiries"} />
    <PageHeader>Enquiries</PageHeader>
    <Breadcrumb
      route={[
        { name: "Parliamentary Papers", href: "/" },
        { name: "Enquiries", href: "/enquiries/" },
      ]}
    />
    <p>
      Please send your enquiries about this collection
      and how to access material to{" "}
      <a href="https://www.sl.nsw.gov.au/research-and-collections/ask-librarian">
        Ask a Librarian
      </a>.
    </p>
  </Layout>
)
